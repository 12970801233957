/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { FC, useEffect } from 'react';
import { PageProps, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '@/common/ui/template/Layout';

// DEFINE, CONST
import NEWCAR_DEFINE from '@/newcar/util/define';
import NEWCAR_CONST from '@/newcar/util/const';

// Component
import FAQAccordion from '@/newcar/ui/top/organism/FAQAccordion';
import DifferencePointAccordion from '@/newcar/ui/top/organism/DifferencePointAccordion';
import DifferencePointTab from '@/newcar/ui/top/organism/DifferencePointTab';
import ColumnCarousel from '@/newcar/ui/top/organism/ColumnCarousel';
import SearchNewcar from '@/newcar/ui/top/organism/SearchNewcar';
import SearchNewcarHorizontalArrangement from '@/newcar/ui/top/organism/SearchNewcarHorizontalArrangement';
import ScrollHint from 'scroll-hint';
import RankingTop from '@/newcar/ui/top/organism/RankingTop';
import Header from '@/newcar/ui/common/organism/Header';
import Footer from '@/newcar/ui/common/organism/Footer';
import SEO from '@/newcar/ui/top/organism/SEO';
import StructuredFAQ from '@/newcar/ui/top/organism/StructuredFAQ';
import searchThumbnail from '@/common/ui/img/search-thumbnail.png';
import useNewCarTopQuery from '@/newcar/ui/top/query/useNewCarTopQuery';
import UserVoiceCard from '@/newcar/ui/common/organism/UserVoiceCard';
import CarDigest from '@/common/core/model/carDigest';
import CarDetail from '@/common/core/model/carDetail';
import SwiperSp from '@/newcar/ui/top/organism/SwiperSp';
import SwiperPc from '@/newcar/ui/top/organism/SwiperPc';
import ScrollToTop from '@/common/ui/organism/ScrollToTop';
import AfterSupport from '@/newcar/ui/top/organism/AfterSupport';
import { jst } from '@/common/util/datetime';
import { DifferencePointList } from '@/newcar/ui/top/organism/DifferencePointList';

// style
import * as styles from '@/newcar/ui/top/template/index.module.styl';
// モジュール化なしで適用が必要なclass群
import '@/newcar/ui/top/organism/TopPageCustom.styl';

// image
import fvBlackTextPattern from '@/newcar/ui/top/img/mainvisual/fv-black-text-pattern.jpg';
import fvBlackTextPatternAvif from '@/newcar/ui/top/img/mainvisual/fv-black-text-pattern.avif';
import fvBlackTextPatternPC from '@/newcar/ui/top/img/mainvisual/fv-black-text-pattern-pc.png';
import fvBlackTextPatternPcAvif from '@/newcar/ui/top/img/mainvisual/fv-black-text-pattern-pc.avif';
import quickLogo from '@/newcar/ui/top/img/quick/quick_logo.svg';
import nbox from '@/newcar/ui/top/img/car-image/nbox.jpg';
import nboxAvif from '@/newcar/ui/top/img/car-image/nbox.avif';
import alto from '@/newcar/ui/top/img/car-image/alto.jpg';
import altoAvif from '@/newcar/ui/top/img/car-image/alto.avif';
import nboxQuick from '@/newcar/ui/top/img/car-image/nbox-quick.jpg';
import nboxQuickAvif from '@/newcar/ui/top/img/car-image/nbox-quick.avif';
import altoQuick from '@/newcar/ui/top/img/car-image/alto-quick.jpg';
import altoQuickAvif from '@/newcar/ui/top/img/car-image/alto-quick.avif';
import easyTypeLabel from '@/newcar/ui/top/img/quick/easy_type_label.svg';
import peaceTypeLabel from '@/newcar/ui/top/img/quick/peace_type_label.svg';
import arrowIconWhite from '@/newcar/ui/common/img/object/icon/arrow-icon-white.svg';
import arrowIconBlack from '@/newcar/ui/common/img/object/icon/arrow-icon-black.svg';
import carmokunCmbanner from '@/newcar/ui/top/img/banners/carmokun-cmbanner.jpg';
import carmokunCmbannerAvif from '@/newcar/ui/top/img/banners/carmokun-cmbanner.avif';
import corporateIndividual from '@/newcar/ui/top/img/banners/corporate_individual_mini.png';
import corporateIndividualAvif from '@/newcar/ui/top/img/banners/corporate_individual_mini.avif';
import bnrShoplist from '@/newcar/ui/top/img/banners/bnr_shoplist.jpg';
import bnrShoplistAvif from '@/newcar/ui/top/img/banners/bnr_shoplist.avif';
import ytLogo from '@/newcar/ui/top/img/socialmedia/yt_logo_rgb_light.png';
import ytLogoAvif from '@/newcar/ui/top/img/socialmedia/yt_logo_rgb_light.avif';
import facebook from '@/newcar/ui/common/img/logo/facebook.png';
import facebookAvif from '@/newcar/ui/common/img/logo/facebook.avif';
import twitter from '@/newcar/ui/common/img/logo/x-logo-black.png';
import twitterAvif from '@/newcar/ui/common/img/logo/x-logo-black.avif';
import line from '@/newcar/ui/common/img/logo/line.svg';
import orixSignatureLogo from '@/newcar/ui/common/img/logo/orix_signature_logo.jpg';
import orixSignatureLogoAvif from '@/newcar/ui/common/img/logo/orix_signature_logo.avif';
import pickupIcon from '@/newcar/ui/common/img/object/project/top-ab/pickup-icon.svg';
import tripleCrownHeading from '@/newcar/ui/top/img/triple-crown/img-triple-crown-heading.png';
import tripleCrownHeadingAvif from '@/newcar/ui/top/img/triple-crown/img-triple-crown-heading.avif';
import tripleCrownPointFirst from '@/newcar/ui/top/img/triple-crown/img-triple-crown-point-first.png';
import tripleCrownPointFirstAvif from '@/newcar/ui/top/img/triple-crown/img-triple-crown-point-first.avif';
import tripleCrownPointSecond from '@/newcar/ui/top/img/triple-crown/img-triple-crown-point-second.png';
import tripleCrownPointSecondAvif from '@/newcar/ui/top/img/triple-crown/img-triple-crown-point-second.avif';
import tripleCrownPointThird from '@/newcar/ui/top/img/triple-crown/img-triple-crown-point-third.png';
import tripleCrownPointThirdAvif from '@/newcar/ui/top/img/triple-crown/img-triple-crown-point-third.avif';
import ratingStar from '@/newcar/ui/common/img/reviews/bnr-google-point.png';
import ratingStarAvif from '@/newcar/ui/common/img/reviews/bnr-google-point.avif';
import pointFirst from '@/newcar/ui/top/img/points/img-point-first.png';
import pointFirstAvif from '@/newcar/ui/top/img/points/img-point-first.avif';
import pointSecond from '@/newcar/ui/top/img/points/img-point-second.png';
import pointSecondAvif from '@/newcar/ui/top/img/points/img-point-second.avif';
import pointThird from '@/newcar/ui/top/img/points/img-point-third.png';
import pointThirdAvif from '@/newcar/ui/top/img/points/img-point-third.avif';
import pointFourth from '@/newcar/ui/top/img/points/img-point-fourth.png';
import pointFourthAvif from '@/newcar/ui/top/img/points/img-point-fourth.avif';
import channelHeading from '@/newcar/ui/top/img/channel/img-channel-heading.png';
import channelHeadingAvif from '@/newcar/ui/top/img/channel/img-channel-heading.avif';
import channelHeadingPc from '@/newcar/ui/top/img/channel/img-channel-heading-pc.png';
import channelHeadingPcAvif from '@/newcar/ui/top/img/channel/img-channel-heading-pc.avif';
import channelThumbnailFirst from '@/newcar/ui/top/img/channel/img-channel-thumbnail-first.png';
import channelThumbnailFirstAvif from '@/newcar/ui/top/img/channel/img-channel-thumbnail-first.avif';
import channelThumbnailSecond from '@/newcar/ui/top/img/channel/img-channel-thumbnail-second.png';
import channelThumbnailSecondAvif from '@/newcar/ui/top/img/channel/img-channel-thumbnail-second.avif';
import channelThumbnailThird from '@/newcar/ui/top/img/channel/img-channel-thumbnail-third.png';
import channelThumbnailThirdAvif from '@/newcar/ui/top/img/channel/img-channel-thumbnail-third.avif';
import channelThumbnailFourth from '@/newcar/ui/top/img/channel/img-channel-thumbnail-fourth.png';
import channelThumbnailFourthAvif from '@/newcar/ui/top/img/channel/img-channel-thumbnail-fourth.avif';
import mediaLogo from '@/newcar/ui/top/img/media/img-media-logo.png';
import mediaLogoAvif from '@/newcar/ui/top/img/media/img-media-logo.avif';
import socialContributionsHeading from '@/newcar/ui/top/img/social-contributions/img-social-contributions-heading.png';
import socialContributionsHeadingAvif from '@/newcar/ui/top/img/social-contributions/img-social-contributions-heading.avif';
import bnrUsedcar from '@/newcar/ui/top/img/banners/bnr-usedcar.png';
import bnrUsedcarAvif from '@/newcar/ui/top/img/banners/bnr-usedcar.avif';
import sell from '@/newcar/ui/top/img/banners/sell.svg';
import bnrMagazine from '@/newcar/ui/top/img/banners/bnr-magazine.svg';
import satisfactionBatch from '@/newcar/ui/top/img/uservoice/satisfaction-batch.svg';
import satisfactionStar from '@/newcar/ui/top/img/uservoice/satisfaction-star.svg';

type TopPageContext = {
  carDigests: CarDigest[];
  carDetail: CarDetail;
}

const Page: FC<PageProps<{}, TopPageContext>> = ({ pageContext }) => {
  const {
    carDigests,
    carDetail,
  } = pageContext;

  // 車両情報から最安値を取得する
  const getMinPrice = (carId: string) => {
    const targetDigest = carDigests.find((digest) => String(digest.id__normalized) === carId);
    if (!targetDigest || !targetDigest.taxedMinPrice11) return '';
    return (targetDigest.taxedMinPrice11).toLocaleString();
  };

  // クイック料金（最安値+もらえるオプション）を取得する
  const getQuickPrice = (carId: string) => {
    const vehicleType = carDetail.vehicleTypes?.find((item) => item.id.toString() === carId);
    if (!vehicleType || !vehicleType.vehicleGrades) return '';
    const grade = vehicleType.vehicleGrades
      .filter((vehicleGrade) => vehicleGrade.leasingFee132)
      .reduce((a, b) => ((a.leasingFee132 && b.leasingFee132 && a.leasingFee132 > b.leasingFee132) ? b : a));
    const freeTransferDiffLeasingFeeTaxed = grade.freeTransferDiffLeasingFeeTaxed132 || 0;
    const quickPrice = parseInt(getMinPrice(carId).replace(',', ''), 10) + freeTransferDiffLeasingFeeTaxed;
    return quickPrice.toLocaleString();
  };

  // 車両情報から画像URLを取得する
  const getThumbnailURL = (carId: string) => {
    const targetCarThumbnail = carDigests.find((digest) => String(digest.id__normalized) === carId);
    if (!targetCarThumbnail) return '';
    return (targetCarThumbnail.thumbnailUrl);
  };

  // 車両情報から燃費を取得する
  const getWLTC = (carId: string) => {
    const targetCarWLTC = carDigests.find((digest) => String(digest.id__normalized) === carId);
    if (!targetCarWLTC) return '';
    return (targetCarWLTC.wltc);
  };

  // クイック：お手軽タイプ
  const quickEasyPath = `/quick/${NEWCAR_CONST.QUICK.ID.EASY}`;
  const quickEasyPrice = getQuickPrice(NEWCAR_CONST.QUICK.ID.EASY);

  // クイック：安心快適タイプ
  const quickReliefPath = `/quick/${NEWCAR_CONST.QUICK.ID.RELIEF}`;
  const quickReliefPrice = getQuickPrice(NEWCAR_CONST.QUICK.ID.RELIEF);

  useEffect(() => {
    if (window.parent.screen.width <= 1024) {
      ((): ScrollHint => new ScrollHint('.js-scrollable', {
        offset: 0,
        i18n: {
          scrollable: 'スクロールできます',
        },
      }))();
    }
  }, []);

  const newcarTop = useNewCarTopQuery();

  return (
    <Layout>
      <SEO />
      <StructuredFAQ />
      <Helmet>
        <meta name="thumbnail" content={process.env.GATSBY_SITE_URL + searchThumbnail} />
        {/* scroll-hint のCSS読み込み */}
        <link
          rel="stylesheet"
          href="https://unpkg.com/scroll-hint@1.1.2/css/scroll-hint.css"
        />
      </Helmet>
      {/* ヘッダー */}

      {/* オリジナル */}

      <div id="ab-nav-origin">
        <Header />
      </div>

      <main className={`is-block ${styles.backgroundWarningPc}`}>
        <div className="has-background-white"><h1 className={`is-size-7 is-padding-left-2 container ${styles.container}`}>新車・中古車カーリース(車のサブスク)なら 定額カルモくん</h1></div>
        {/* SPメインビジュアル */}
        <section className="is-relative is-hidden-tablet">
          <div>
            <div className={`is-relative ${styles.topMvContainer}`}>

              {/* メインビジュアル SP ~ Tablet(1023pxまで) */}
              <div className={`is-relative is-hidden-tablet ${styles.topMvIntextBox}`}>
                <picture>
                  <source srcSet={fvBlackTextPatternAvif} type="image/avif" />
                  <img
                    src={fvBlackTextPattern}
                    alt="毎月定額、ラクラク支払い さらに契約満了後に車がもらえる！"
                    width="375"
                    height="600"
                  />
                </picture>
                {/* SP_FV内バナーカルーセル */}
                <SwiperSp />
              </div>

              {/*  クイック審査SP版  */}
              <div
                className={`column is-hidden-tablet is-padding-right-4 is-padding-left-4 has-text-centered ${styles.boxQuick}`}
              >
                <h3 className="columns is-mobile is-centered is-padding-2 has-text-weight-bold title">
                  <p id="quick-slogan-sp" className={`column is-narrow is-padding-2 ${styles.boxQuickTitle}`}>
                    初めての車や納車をお急ぎなら
                    <br />
                    <span className="has-text-primary">最短翌日結果が分かる</span>
                    クイック審査がおすすめ♪
                  </p>
                </h3>
                <div className={`columns is-mobile is-margin-bottom-4 column_gap ${styles.column_gap}`}>
                  <div className={`column radius-mini has-background-white ${styles.quick_boxin}`}>
                    <a
                      id="quick-easy-path-sp"
                      className="quick-url is-block is-padding-2"
                      href={quickEasyPath}
                    >
                      <div className="quick-name is-size-4-desktop is-size-6-touch has-text-black-ter has-text-weight-bold">
                        お手軽タイプ軽自動車
                      </div>
                      <div className="is-margin-top-1 is-flex-item-5 is-padding-2 is-valign-center">
                        <picture>
                          <source srcSet={altoQuickAvif} type="image/avif" />
                          <img
                            src={altoQuick}
                            className={`is-margin-0 ${styles.quickImg}`}
                            alt="お手軽タイプ軽自動車"
                            width="117"
                            height="48"
                          />
                        </picture>
                      </div>
                      <div
                        className={`columns is-mobile ${styles.quick_price}`}
                      >
                        <span className="column is-11 is-size-6 has-text-black">
                          <span className={styles.isFold}>月額</span>
                          <strong className="tax-free-price is-size-3-desktop is-size-4-touch">
                            {quickEasyPrice}
                          </strong>
                          <span className={styles.isFold}>円~(税込)</span>
                        </span>
                        <div className="column">
                          <img
                            src={arrowIconBlack}
                            alt="このタイプで審査申込ボタン"
                            width="13"
                            height="13"
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className={`column radius-mini has-background-white ${styles.quick_boxin}`}>
                    <a
                      id="quick-relief-path-sp"
                      className="quick-url is-block is-padding-2"
                      href={quickReliefPath}
                    >
                      <div className="quick-name is-size-4-desktop is-size-6-touch has-text-black-ter has-text-weight-bold">
                        安心快適タイプ軽自動車
                      </div>
                      <div className="is-margin-top-1 is-flex-item-5 is-padding-2 is-valign-center">
                        <picture>
                          <source srcSet={nboxQuickAvif} type="image/avif" />
                          <img
                            src={nboxQuick}
                            className={`is-margin-0 ${styles.quickImg}`}
                            alt="安心快適タイプ軽自動車"
                            width="113"
                            height="48"
                          />
                        </picture>
                      </div>
                      <div
                        className={`columns is-mobile ${styles.quick_price}`}
                      >
                        <span className="column is-11 is-size-6 has-text-black">
                          <span className={styles.isFold}>月額</span>
                          <strong className="tax-free-price is-size-3-desktop is-size-4-touch">
                            {quickReliefPrice}
                          </strong>
                          <span className={styles.isFold}>円~(税込)</span>
                        </span>
                        <div className="column">
                          <img
                            src={arrowIconBlack}
                            alt="このタイプで審査申込ボタン"
                            width="13"
                            height="13"
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* メインビジュアル PC */}
            <div className={`container is-hidden-mobile ${styles.mvCopy}`}>
              <div>
                <picture>
                  <source srcSet={fvBlackTextPatternPcAvif} type="image/avif" />
                  <source srcSet={fvBlackTextPatternPC} />
                  <img
                    src={fvBlackTextPatternPC}
                    alt="毎月定額、ラクラク支払い さらに契約満了後に車がもらえる！"
                    width="1758"
                    height="714"
                  />
                </picture>
              </div>
            </div>

            <div className={`container ${styles.quickAndSearchContainer}`}>
              <div className="columns">
                {/*  クイック審査PC版  */}
                <div
                  className={`column is-hidden-mobile has-background-white has-text-centered ${styles.boxQuick} ${styles.columnBoxshadow}`}
                >
                  <h3 className="columns is-mobile is-padding-3 has-text-weight-bold title" id="original-title">
                    <img
                      id="quick-logo-pc"
                      className={`column is-narrow ${styles.isQuicklogoHeight}`}
                      src={quickLogo}
                      alt="クイック審査"
                    />
                    <p id="quick-slogan-pc" className="column is-narrow is-size-6 is-padding-2 has-text-weight-left">
                      お急ぎの方に特にオススメ！
                    </p>
                  </h3>
                  {/*  ABテスト用タイトルPC版  */}
                  <h3 className="columns is-mobile is-padding-3 has-text-weight-bold title is-hidden" id="ab-title">
                    <img
                      className={`column is-narrow ${styles.isQuicklogoHeight}`}
                      src={pickupIcon}
                      alt="pickup"
                    />
                    <p className="column is-narrow is-size-6 is-padding-2 has-text-weight-left">
                      おすすめ商品
                    </p>
                  </h3>

                  <div className="columns is-mobile is-margin-bottom-3">
                    <div
                      className={`column is-half ${styles.isQuickBorder}`}
                      id="ab-original-quick-mira"
                    >
                      <a
                        id="quick-easy-path-pc"
                        className="quick-url is-block is-padding-2"
                        href={quickEasyPath}
                      >
                        <div className="quick-name is-size-4-desktop is-size-6-touch has-text-black-ter has-text-weight-bold">
                          お手軽タイプ軽自動車
                        </div>
                        <div className="is-flex-item-5 is-padding-2 is-valign-center">
                          <picture>
                            <source srcSet={altoAvif} type="image/avif" />
                            <img
                              src={alto}
                              className={`is-margin-0 ${styles.quickImg}`}
                              alt="お手軽タイプ軽自動車"
                            />
                          </picture>
                        </div>
                        <div className="center-contents is-flex-item-2 is-valign-center">
                          <img
                            src={easyTypeLabel}
                            alt="なるべく早く・低燃費・小回り抜群"
                          />
                        </div>
                        <div className={styles.quick_price}>
                          <span className="is-size-6 has-text-black">
                            月額
                            <strong className="tax-free-price is-size-3-desktop is-size-2-touch">
                              {quickEasyPrice}
                            </strong>
                            円~(税込)
                          </span>
                        </div>
                        <div className={`is-margin-top-4 ${styles.quick_button}`}>
                          <div className="button has-background-redblack has-text-centered">
                            <strong
                              className={`is-size-5-desktop is-size-5-tablet is-size-7-mobile has-text-white ${styles.quickText}`}
                            >
                              このタイプで審査申込
                            </strong>
                            <img
                              src={arrowIconWhite}
                              alt="このタイプで審査申込ボタン"
                              className={styles.icon_quick}
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="quick-10013 column is-half" id="ab-original-quick-nbox">
                      <a
                        id="quick-relief-path-pc"
                        className="quick-url is-block is-padding-2"
                        href={quickReliefPath}
                      >
                        <div className="quick-name is-size-4-desktop is-size-6-touch has-text-black-ter has-text-weight-bold">
                          安心快適タイプ軽自動車
                        </div>
                        <div className="is-flex-item-5 is-padding-2 is-valign-center">
                          <picture>
                            <source srcSet={nboxAvif} type="image/avif" />
                            <img src={nbox} className={`is-margin-0 ${styles.quickImg}`} alt="安心快適タイプ軽自動車" />
                          </picture>
                        </div>
                        <div className="center-contents is-flex-item-2 is-valign-center">
                          <img
                            src={peaceTypeLabel}
                            alt="簡単安心/安心装備/スライドドア"
                          />
                        </div>
                        <div className={styles.quick_price}>
                          <span className="is-size-6 has-text-black">
                            月額
                            <strong className="tax-free-price is-size-3-desktop is-size-2-touch">
                              {quickReliefPrice}
                            </strong>
                            円~(税込)
                          </span>
                        </div>
                        <div className={`is-margin-top-4 ${styles.quick_button}`}>
                          <div className="button has-background-redblack has-text-centered">
                            <strong className="quick-text is-size-5-desktop is-size-5-tablet is-size-7-mobile has-text-white">
                              このタイプで審査申込
                            </strong>
                            <img
                              src={arrowIconWhite}
                              alt="このタイプで審査申込ボタン"
                              className={styles.icon_quick}
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                {/*  検索  */}
                <div className={`column ${styles.newcarSearchContainer}`}>
                  <SearchNewcar />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="is-relative is-hidden-mobile">
          <div>
            <div>
              {/* メインビジュアル PC */}
              <div
                className={`is-hidden-mobile ${styles.topMainvisualBackgroundPc}`}
              />
            </div>

            {/* メインビジュアル PC */}
            <div className={`container is-hidden-mobile ${styles.mvCopy}`}>
              <div>
                <picture>
                  <source srcSet={fvBlackTextPatternPcAvif} type="image/avif" />
                  <source srcSet={fvBlackTextPatternPC} />
                  <img
                    src={fvBlackTextPatternPC}
                    alt="毎月定額、ラクラク支払い さらに契約満了後に車がもらえる！"
                    width="1758"
                    height="714"
                  />
                </picture>
              </div>
              <span className={styles.mvCopyNotice}>
                ※1 車種や契約内容によります。
              </span>
            </div>

            {/* キャッチコピー&バナーエリア全体 wrapper Original */}
            <div className={`container ${styles.mvAdjust}`} />

            <div className={`container ${styles.quickAndSearchContainer} ${styles.quickAndSearchContainerMargin}`}>
              <div className="columns">
                {/*  クイック審査PC版  */}
                <div className={`column is-5 ${styles.bunnerQuickArea}`}>
                  <div
                    className={`column is-hidden-mobile is-relative has-text-centered ${styles.boxQuick}`}
                  >
                    {/* PC_FV内バナーカルーセル */}
                    <SwiperPc />
                  </div>
                  <div
                    className={`is-hidden-mobile has-background-white has-text-centered ${styles.boxQuick} ${styles.boxQuickRadius} ${styles.columnBoxshadow}`}
                  >
                    <h3 className="is-padding-3 has-text-weight-bold" id="original-title">
                      <span
                        id="quick-slogan-pc"
                        className={styles.quickHeading}
                      >
                        初めての車や納車をお急ぎなら
                        <br />
                        <span className="has-text-primary">最短翌日結果が分かる</span>
                        クイック審査がおすすめ♪
                      </span>
                    </h3>

                    <div className="columns is-mobile is-margin-bottom-3 is-margin-top-3">
                      <div
                        className={`column is-half ${styles.isQuickBorder}`}
                        id="ab-original-quick-mira"
                      >
                        <a
                          id="quick-easy-path-pc"
                          className="quick-url is-block is-padding-2"
                          href={quickEasyPath}
                        >
                          <div className="quick-name is-size-5-desktop is-size-6-touch has-text-black-ter has-text-weight-bold">
                            お手軽タイプ軽自動車
                          </div>
                          <div className="is-flex-item-5 is-padding-2 is-valign-center">
                            <picture>
                              <source srcSet={altoQuickAvif} type="image/avif" />
                              <img
                                src={altoQuick}
                                className={`is-margin-0 ${styles.quickImg}`}
                                alt="お手軽タイプ軽自動車"
                                width="513"
                                height="210"
                              />
                            </picture>
                          </div>
                          <div className={styles.quick_price}>
                            <span className="is-size-6 has-text-black">
                              <span className={styles.isFold}>月額</span>
                              <strong className="tax-free-price is-size-3-desktop is-size-3-touch">
                                {quickEasyPrice}
                              </strong>
                              <span className={styles.isFold}>円~(税込)</span>
                            </span>
                          </div>
                          <div className={`is-margin-top-4 ${styles.quick_button}`}>
                            <div className="button has-background-redblack has-text-centered">
                              <strong
                                className={`is-size-6-desktop is-size-7-tablet is-size-7-mobile has-text-white ${styles.quickText}`}
                              >
                                このタイプで審査申込
                              </strong>
                              <img
                                src={arrowIconWhite}
                                alt="このタイプで審査申込ボタン"
                                className={styles.icon_quick}
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="quick-10013 column is-half" id="ab-original-quick-nbox">
                        <a
                          id="quick-relief-path-pc"
                          className="quick-url is-block is-padding-2"
                          href={quickReliefPath}
                        >
                          <div className="quick-name is-size-5-desktop is-size-6-touch has-text-black-ter has-text-weight-bold">
                            安心快適タイプ軽自動車
                          </div>
                          <div className="is-flex-item-5 is-padding-2 is-valign-center">
                            <picture>
                              <source srcSet={nboxQuickAvif} type="image/avif" />
                              <img
                                src={nboxQuick}
                                className={`is-margin-0 ${styles.quickImg}`}
                                alt="安心快適タイプ軽自動車"
                                width="546"
                                height="231"
                              />
                            </picture>
                          </div>
                          <div className={styles.quick_price}>
                            <span className="is-size-6 has-text-black">
                              月額
                              <strong className="tax-free-price is-size-3-desktop is-size-3-touch">
                                {quickReliefPrice}
                              </strong>
                              円~(税込)
                            </span>
                          </div>
                          <div className={`is-margin-top-4 ${styles.quick_button}`}>
                            <div className="button has-background-redblack has-text-centered">
                              <strong className="quick-text is-size-6-desktop is-size-7-tablet is-size-7-mobile has-text-white">
                                このタイプで審査申込
                              </strong>
                              <img
                                src={arrowIconWhite}
                                alt="このタイプで審査申込ボタン"
                                className={styles.icon_quick}
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  検索  */}
                <div className="column is-7">
                  <SearchNewcarHorizontalArrangement />
                </div>
              </div>
              {/*
                <div className="is-size-4-tablet is-size-7-mobile letter-space-s is-hidden-mobile">
                <p className={`is-size-7 is-margin-top-1 ${styles.maincatchcopyComment}`}>
                  ※1)車種や契約内容による
                </p>
              </div> */}
            </div>
          </div>
        </section>

        <div
          className={`has-background-warning ${styles.contentsPaddingBottom}`}
        >

          {/*  おすすめの車ランキング  */}
          <RankingTop
            getMinPrice={getMinPrice}
            getThumbnailURL={getThumbnailURL}
            getWLTC={getWLTC}
          />

          {/* 新セクション_三冠とレーティング */}
          <section id="tripleCrown">
            <div className={`is-relative is-padding-4 ${styles.tripleCrownContainer}`}>
              <div className={styles.tripleCrownContentsTop}>
                <h2
                  className={`has-text-centered ${styles.contentsTitle} ${styles.big} ${styles.isTripleCrown}`}
                  itemProp="name"
                >
                  おかげさまで高評価いただきました
                </h2>
                <div className={styles.tripleCrownContentsTopInner}>
                  <h3 className={styles.tripleCrownHeading}>
                    <picture>
                      <source srcSet={tripleCrownHeadingAvif} type="image/avif" />
                      <img
                        className={styles.tripleCrownHeadingImage}
                        src={tripleCrownHeading}
                        alt="国内No.1カーリース 3冠獲得 おトクにマイカー定額カルモくん"
                        width="837"
                        height="543"
                        loading="lazy"
                      />
                    </picture>
                  </h3>
                  <ul className={styles.tripleCrownList}>
                    <li className={styles.tripleCrownListItem}>
                      <picture>
                        <source srcSet={tripleCrownPointFirstAvif} type="image/avif" />
                        <img
                          src={tripleCrownPointFirst}
                          alt="ファイナンシャルプランナーが選ぶ「コストパフォーマンスが高い」と思うカーリースNo.1"
                          loading="lazy"
                          width="1065"
                          height="291"
                        />
                      </picture>
                    </li>
                    <li className={styles.tripleCrownListItem}>
                      <picture>
                        <source srcSet={tripleCrownPointSecondAvif} type="image/avif" />
                        <img
                          src={tripleCrownPointSecond}
                          alt="カーリース利用経験者が選ぶ「おすすめしたい」と思うカーリース No.1"
                          loading="lazy"
                          width="1065"
                          height="291"
                        />
                      </picture>
                    </li>
                    <li className={styles.tripleCrownListItem}>
                      <picture>
                        <source srcSet={tripleCrownPointThirdAvif} type="image/avif" />
                        <img
                          src={tripleCrownPointThird}
                          alt="カーディーラー勤務者が選ぶ「サポートが充実している」と思うカーリース No.1"
                          loading="lazy"
                          width="1065"
                          height="291"
                        />
                      </picture>
                    </li>
                  </ul>
                </div>
                <span className={styles.tripleCrownNotice}>
                  【出典】2024年1月期_ブランドのイメージ調査（調査1～3） 調査機関：日本マーケティングリサーチ機構 調査期間：2023年12月14日～2024年1月9日 n数：227（※調査1）、103（※調査2）、177（※調査3）/調査方法：Webアンケート 調査対象者：https://jmro.co.jp/r01525/ 備考：本調査は個人のブランドに対するイメージを元にアンケートを実施し集計しております。/本ブランドの利用有無は聴取しておりません。/効果効能等や優位性を保証するものではございません。/競合2位との差は5％以上。
                </span>
              </div>
            </div>
          </section>
          {/* // 新セクション_三冠とレーティング */}

          {/* カルモ利用者の声  */}
          <section id="userVoice" itemScope itemType="http://schema.org/Product">
            <div
              className={`has-background-light-yellow ${styles.contentsMarginTop}`}
            >
              <div className="container">
                <h2
                  className={`has-text-centered ${styles.contentsTitle} ${styles.big} `}
                  itemProp="name"
                >
                  定額カルモくんレビューも続々！
                </h2>

                <div className={styles.listUservoiceWrapper}>
                  <div
                    className={`columns is-centered is-vcentered is-mobile  ${styles.totalEvaluation}`}
                    itemProp="aggregateRating"
                    itemScope
                    itemType="http://schema.org/AggregateRating"
                  >
                    <div className={styles.satisfactionBatch}>
                      <img
                        src={satisfactionBatch}
                        alt="満足度調査"
                        loading="lazy"
                        width="72"
                        height="74"
                      />
                    </div>
                    <p
                      className={`column is-narrow is-size-2 ${styles.satisfactionNum}`}
                      itemProp="ratingValue"
                    >
                      4.6
                    </p>
                    <div
                      className={`column is-narrow ${styles.satisfactionStar}`}
                    >
                      <img
                        src={satisfactionStar}
                        alt="星4.6"
                        loading="lazy"
                        width="131"
                        height="25"
                      />
                    </div>
                    <p className="column is-narrow is-size-6 has-text-primary has-text-weight-semibold">
                      (
                      <span itemProp="reviewCount">140</span>
                      件)
                    </p>
                  </div>
                </div>

                <ul
                  className={`is-flex js-scrollable ${styles.listUserVoice} `}
                >
                  {newcarTop.microcmsNewcarTop?.userVoices?.map((uv) => {
                    const [prefecture] = uv?.prefecture ?? [];
                    return (
                      <li key={uv?.id ?? ''}>
                        <UserVoiceCard
                          className={`${styles.listUserVoiceItem} page-top`}
                          initial={uv?.initial ?? ''}
                          prefecture={prefecture ?? ''}
                          age={uv?.age ?? ''}
                          maker={uv?.maker ?? ''}
                          carModel={uv?.carModel ?? ''}
                          ratingOverall={uv?.ratingOverall ?? 0}
                          ratingWebsite={uv?.ratingWebsite ?? 0}
                          ratingCS={uv?.ratingCS ?? 0}
                          ratingCar={uv?.ratingCar ?? 0}
                          comment={uv?.comment ?? ''}
                          postedAt={jst(uv?.postedAt)}
                        />
                      </li>
                    );
                  })}
                </ul>
                <div className="has-text-centered">
                  <a
                    className="button radius-most is-large is-fixed-width-button is-margin-top-7 is-margin-bottom-7"
                    href="/uservoice/#nationwide-voice"
                  >
                    もっと見る
                    <span
                      className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className={styles.tripleCrownContentsBottom}>
              <p className={styles.commonHeadingVoice}>さらに！</p>
              <figure className="has-text-centered">
                <picture>
                  <source srcSet={ratingStarAvif} type="image/avif" />
                  <img
                    src={ratingStar}
                    alt="おかげさまで高評価4.3獲得！"
                    width="330"
                    height="193"
                    loading="lazy"
                  />
                </picture>
              </figure>
              <div className="has-text-centered">
                <a
                  className="button radius-most is-large is-fixed-width-button is-margin-top-7 is-margin-bottom-7"
                  href="https://www.google.com/maps/place/%E5%AE%9A%E9%A1%8D%E3%82%AB%E3%83%AB%E3%83%A2%E3%81%8F%E3%82%93/@35.6259572,139.724828,17z/data=!3m1!5s0x60188af98dd4d329:0x5b94c563c0f372f6!4m8!3m7!1s0x60188b6825e29dc9:0x699f5240549b6b4d!8m2!3d35.6259529!4d139.7274029!9m1!1b1!16s%2Fg%2F11j2y3vzc5?entry=ttu"
                  target="_blank"
                  rel="noreferrer"
                >
                  Googleの口コミを見る
                  <span
                    className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                  />
                </a>
              </div>
            </div>
          </section>

          {/* 新セクション_選ばれる4つのポイント */}
          <section id="appealPoint">
            <div className={styles.appealPointContainer}>
              <span className={`${styles.commonHeadingVoice} ${styles.isAppealPoint}`}>
                累計申込数25万件突破！
              </span>
              <div className={`has-background-warning ${styles.contentsMarginTop}`}>
                <div className={`box container is-margin-auto ${styles.appealPointCard}`}>
                  <h2
                    className={`${styles.big} ${styles.commonTitle} ${styles.isAppealPoint} has-text-centered ${styles.contentsTitle}`}
                  >
                    <span className={styles.commonTitleMinText}>定額カルモくんのマイカーリースが</span>
                    <br className="is-hidden-tablet" />
                    選ばれる４つのポイント！
                  </h2>

                  <div
                    className={`${styles.appealPointContent} ${styles.contentsMarginTop}`}
                  >
                    {/* point 1 */}
                    <div className={styles.appealPointContentItem}>
                      <h3 className={styles.appealPointHeading}>
                        業界最安水準！
                        <span className={styles.appealPointAsteriskSub}>※1</span>
                        <br />
                        初期費用0円

                      </h3>
                      <div className={`${styles.appealPointThumbnailContainer} ${styles.isFirst}`}>
                        <picture>
                          <source srcSet={pointFirstAvif} type="image/avif" />
                          <img
                            src={pointFirst}
                            alt="業界最安水準の価格"
                            loading="lazy"
                            width="903"
                            height="272"
                          />
                        </picture>
                      </div>
                      <p className={styles.appealPointText}>
                        <span className="has-text-weight-bold">頭金・ボーナス払い０円</span>
                        、車検代・メンテ代・各種税金・自賠責保険料コミ！急な出費に困ることなく月1万円台〜カーライフを楽しめます。
                        <span className={`is-block ${styles.appealPointAsterisk}`}>※メンテナンスプラン加入時</span>
                      </p>
                      <div className={`${styles.appealPointButtonContainer} has-text-centered`}>
                        <a
                          className={`${styles.appealPointButtonItem} button radius-most`}
                          href="/docs/fee/"
                        >
                          月額料金に含まれるのは？
                          <span
                            className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                          />
                        </a>
                      </div>
                    </div>
                    {/* //_point 1 */}

                    {/* point 2 */}
                    <div className={styles.appealPointContentItem}>
                      <h3 className={styles.appealPointHeading}>
                        車がもらえる！
                        <span className={styles.appealPointAsteriskSub}>※2</span>
                        <br />
                        抜群の自由度
                      </h3>
                      <div className={`${styles.appealPointThumbnailContainer} ${styles.isSecond}`}>
                        <picture>
                          <source srcSet={pointSecondAvif} type="image/avif" />
                          <img
                            src={pointSecond}
                            alt="返却不要＆抜群の自由度"
                            loading="lazy"
                            width="940"
                            height="649"
                          />
                        </picture>
                      </div>
                      <p className={styles.appealPointText}>
                        <span className="has-text-weight-bold">車は最終的に返却するかもらうか選べます！</span>
                        <span className={styles.appealPointAsteriskSub}>※2</span>
                        <br />
                        車をもらう場合、ディーラーで購入するのと変わらない使い勝手だから、借り物感なし！走行距離制限もないし、カスタムもOKです。
                      </p>
                      <div className={`${styles.appealPointButtonContainer} has-text-centered`}>
                        <a
                          className={`${styles.appealPointButtonItem} button radius-most ${styles.commonButtonGap}`}
                          href="/docs/moraeru/"
                        >
                          クルマを返さなくていいの？
                          <span
                            className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                          />
                        </a>
                      </div>
                    </div>
                    {/* //_point 2 */}

                    {/* point 3 */}
                    <div className={styles.appealPointContentItem}>
                      <h3 className={styles.appealPointHeading}>
                        長期保証＆
                        <br className="is-hidden-mobile" />
                        保険の安心感
                      </h3>
                      <div className={`${styles.appealPointThumbnailContainer} ${styles.isThird}`}>
                        <picture>
                          <source srcSet={pointThirdAvif} type="image/avif" />
                          <img
                            src={pointThird}
                            alt="長期保証＆保険の安心感"
                            loading="lazy"
                            width="705"
                            height="440"
                          />
                        </picture>
                      </div>
                      <p className={styles.appealPointText}>
                        定額カルモくんのメンテナンスプランは、
                        <span className="has-text-weight-bold">
                          最長11年の安心保証
                          <span className={styles.appealPointAsteriskSub}>※4</span>
                          が付帯
                        </span>
                        します。さらにリース車両に特化した特約がついたカルモ契約者様専用自動車保険もご用意しております。
                      </p>
                      <div className={`${styles.appealPointButtonContainer} has-text-centered`}>
                        <a
                          className={`${styles.appealPointButtonItem} button radius-most`}
                          href="/docs/carinsurance/"
                        >
                          カルモ専用の自動車保険とは？
                          <span
                            className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                          />
                        </a>
                      </div>
                    </div>
                    {/* //_point 3 */}

                    {/* point 4 */}
                    <div className={styles.appealPointContentItem}>
                      <h3 className={styles.appealPointHeading}>
                        接客満足度
                        <br className="is-hidden-mobile" />
                        92.7%
                      </h3>
                      <div className={`${styles.appealPointThumbnailContainer} ${styles.isFourth}`}>
                        <picture>
                          <source srcSet={pointFourthAvif} type="image/avif" />
                          <img
                            src={pointFourth}
                            alt="接客満足度92.7%"
                            loading="lazy"
                            width="529"
                            height="396"
                          />
                        </picture>
                      </div>
                      <p className={styles.appealPointText}>
                        お客様のご要望に寄り添うことを第一に、
                        <span className="has-text-weight-bold">メーカー横断で最適なカーライフをご提案</span>
                        いたします。LINE・メール・電話でご相談を承っており、できるだけ早くご連絡をするよう心がけております。
                      </p>
                      <div className={`${styles.appealPointButtonContainer} has-text-centered`}>
                        <a
                          className={`${styles.appealPointButtonItem} button radius-most ${styles.commonButtonGap}`}
                          href="/docs/staff/"
                        >
                          スタッフはどんな人？
                          <span
                            className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                          />
                        </a>
                      </div>
                    </div>
                    {/* //_point 4 */}
                  </div>
                </div>

                <ul className={`${styles.appealPointNoticeList} container`}>
                  <li className={styles.appealPointNoticeListItem}>
                    ※１）文末の制作日における調査結果に基づく。調査概要はコンテンツポリシー参照。
                  </li>
                  <li className={styles.appealPointNoticeListItem}>
                    ※２）7年以上のご契約でもらえるオプション加入時
                  </li>
                  <li className={styles.appealPointNoticeListItem}>
                    ※３）7年以上のご契約
                  </li>
                  <li className={styles.appealPointNoticeListItem}>
                    ※４）プラチナメンテプラン加入時
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* // 新セクション_選ばれる4つのポイント */}

          {/* 新セクション_ココが違う！ */}
          <section>
            <div className={`${styles.differenceContainer} is-relative has-background-white`}>
              <h2
                className={`${styles.big} ${styles.commonTitle} ${styles.contentsTitle} ${styles.isDifference}`}
              >
                <span className={styles.commonTitleMinText}>さらに！</span>
                <br className="is-hidden-tablet" />
                ココも高評価の理由
              </h2>

              <div className="is-hidden-desktop">
                {/* アコーディオンで表示_SP版 */}
                {
                  DifferencePointList.map((data) => (
                    <div
                      className={styles.differenceAccordionItem}
                      key={`differencePoint${data.id}`}
                    >
                      <DifferencePointAccordion
                        title={data.title}
                        text={data.htmlContent}
                        addOrderClass={data.addOrderClass}
                        notice={data.notice}
                      />
                    </div>
                  ))
                }
                {/* //_アコーディオンで表示_SP版 */}
              </div>

              <div className={`${styles.isShowDesktop}`}>
                {/* タブ形式で表示_PC版 */}
                <div
                  className={`${styles.differenceTabContainer}`}
                >
                  {
                    DifferencePointList.map((data) => (
                      <DifferencePointTab
                        id={data.id}
                        title={data.title}
                        text={data.htmlContent}
                        addOrderClass={data.addOrderClass}
                        notice={data.notice}
                        key={`differencePointTab${data.id}`}
                      />
                    ))
                  }
                  {/* //_タブ形式で表示_PC版 */}
                </div>
              </div>

            </div>
          </section>
          {/* // 新セクション_ココが違う！ */}

          {/* ご契約後サポート・特典が充実 */}
          <AfterSupport />
          {/* // ご契約後サポート・特典が充実 */}

          {/* よくある質問 */}
          <section>
            <div className={`is-padding-4 has-background-warning ${styles.contentsMarginTop}`}>
              <div className="box container is-margin-auto">
                <h2
                  className={`is-3 has-text-centered ${styles.contentsTitle} ${styles.small}`}
                >
                  よくあるご質問
                </h2>
                <div
                  className={`columns is-multiline ${styles.contentsMarginTop} ${styles.contentsSmallContainer}`}
                >
                  {newcarTop.microcmsNewcarTop?.faq?.map((qa) => (
                    <div
                      className="column is-full is-padding-3"
                      key={`faq-item-${qa?.question}`}
                    >
                      <FAQAccordion
                        question={qa?.question ?? ''}
                        answer={qa?.answer ?? ''}
                      />
                    </div>
                  ))}
                </div>
                {/* もっと読む */}
                <div className="has-text-centered">
                  <a
                    className="button radius-most is-large is-fixed-width-button is-margin-top-7 is-margin-bottom-7"
                    href="/docs/faq/"
                  >
                    もっと見る
                    <span
                      className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* 新セクション_人気チャンネル */}
        <section>
          <div className={`${styles.channelContainer} is-relative has-background-white`}>
            <h2
              className={`${styles.contentsTitle} ${styles.isChannel}`}
            >
              <span className={styles.isShowUnderTablet}>登録者数1.6万人！人気チャンネル</span>
              <span className={` ${styles.isShowDesktop} ${styles.commonTitle}`}>
                <span className={styles.commonTitleMinText}>
                  チャンネル登録者数1.6万人突破！
                </span>
                人気YouTube運営中！
              </span>
            </h2>
            <div className={`container ${styles.channelThumbnailContainer}`}>
              <picture>
                <source srcSet={channelHeadingAvif} type="image/avif" />
                <img
                  className={styles.isShowUnderTablet}
                  src={channelHeading}
                  alt="カルモくんTV"
                  loading="lazy"
                  width="1035"
                  height="422"
                />
              </picture>
              <picture>
                <source srcSet={channelHeadingPcAvif} type="image/avif" />
                <img
                  className={`${styles.isShowDesktop} ${styles.channelThumbnailImage}`}
                  src={channelHeadingPc}
                  alt="カルモくんTV"
                  loading="lazy"
                  width="1344"
                  height="269"
                />
              </picture>
              <p className={styles.channelDescription}>
                「カルモくんTV」では「車に関する不安」を解決する動画をアップしています！
                <br className="is-hidden-mobile" />
                素敵なカーライフを送るために少しでも参考になる動画を上げていきますのでチャンネル登録と高評価をどうぞよろしくお願いします。
              </p>
            </div>
            <div className={`container js-scrollable ${styles.channelCardContainer}`}>
              <a
                className={styles.channelLink}
                href="https://youtu.be/3gk6AarOqaQ?feature=shared"
                target="_blank"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={channelThumbnailFirstAvif} type="image/avif" />
                  <img
                    className={styles.channelMovieThumbnail}
                    src={channelThumbnailFirst}
                    alt="カルモくんTV"
                    loading="lazy"
                    width="310"
                    height="173"
                  />
                </picture>
              </a>
              <a
                className={styles.channelLink}
                href="https://youtu.be/OhiEYclHSEU?feature=shared"
                target="_blank"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={channelThumbnailSecondAvif} type="image/avif" />
                  <img
                    className={styles.channelMovieThumbnail}
                    src={channelThumbnailSecond}
                    alt="カルモくんTV"
                    loading="lazy"
                    width="310"
                    height="173"
                  />
                </picture>
              </a>
              <a
                className={styles.channelLink}
                href="https://youtu.be/VD-VajkdFy4?feature=shared"
                target="_blank"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={channelThumbnailThirdAvif} type="image/avif" />
                  <img
                    className={styles.channelMovieThumbnail}
                    src={channelThumbnailThird}
                    alt="カルモくんTV"
                    loading="lazy"
                    width="310"
                    height="173"
                  />
                </picture>
              </a>
              <a
                className={styles.channelLink}
                href="https://youtu.be/6Gm7I-0KIdI?feature=shared"
                target="_blank"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={channelThumbnailFourthAvif} type="image/avif" />
                  <img
                    className={styles.channelMovieThumbnail}
                    src={channelThumbnailFourth}
                    alt="カルモくんTV"
                    loading="lazy"
                    width="310"
                    height="173"
                  />
                </picture>
              </a>
            </div>
            <div className="has-text-centered">
              <a
                className="button radius-most is-large is-fixed-width-button is-margin-top-7 is-margin-bottom-7"
                href="https://www.youtube.com/channel/UCQIXe8-YDmE7Z0WzQ7Con5A"
                target="_blank"
                rel="noreferrer"
              >
                公式チャンネルをチェック！
                <span
                  className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                />
              </a>
            </div>
          </div>
        </section>
        {/* // 新セクション_人気チャンネル */}

        {/* pcでは2カラムになるためコンテナ追加 */}
        <div className={styles.mediaAndSocialContainer}>
          {/* メディア掲載実績 */}
          <section>
            <div className={`${styles.mediaContainer} has-background-white ${styles.contentsMarginTop}`}>
              <div
                className={`${styles.commonSectionInner} ${styles.contentsSmallContainer}`}
              >
                <h2
                  className={`is-size-2-tablet is-size-3-mobile is-padding-3 has-text-weight-semibold has-text-centered has-text-redblack ${styles.contentsBandTitle}`}
                >
                  メディア掲載実績
                </h2>
                {/*  CM  */}
                <div className={`${styles.mediaCmBannerContainer} ${styles.youtube_area}`} id="ab-bnr-origin">
                  <a
                    id="cm-banner-original"
                    className={styles.mediaCmBannerLink}
                    href="https://www.youtube.com/watch?v=O62TT-5Aza4"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <picture>
                      <source srcSet={carmokunCmbannerAvif} type="image/avif" />
                      <img
                        src={carmokunCmbanner}
                        className={styles.cmbannerSize}
                        alt="TVCM放映中！CM動画ギャラリー"
                        loading="lazy"
                        width="940"
                        height="528"
                      />
                    </picture>
                  </a>
                </div>
                <p className={styles.mediaText}>
                  テレビ東京系「ワールドビジネスサテライト」他、テレビや雑誌等で多数取り上げられています！
                </p>
                <picture>
                  <source srcSet={mediaLogoAvif} type="image/avif" />
                  <img
                    src={mediaLogo}
                    className="is-padding-3"
                    alt="メディア掲載実績"
                    loading="lazy"
                    width="1005"
                    height="298"
                  />
                </picture>
                {/* もっと見る */}
                <div className="has-text-centered">
                  <a
                    className="button radius-most is-large is-fixed-width-button"
                    href="https://nyle.co.jp/news/media/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    もっと見る
                    <span
                      className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                    />
                  </a>
                </div>
              </div>

            </div>
          </section>

          {/* 新セクション_社会貢献活動 */}
          <section>
            <div className={`${styles.socialContributionsContainer} has-background-white ${styles.contentsMarginTop}`}>
              <div
                className={`${styles.commonSectionInner} ${styles.contentsSmallContainer}`}
              >
                <h2
                  className={`is-size-2-tablet is-size-3-mobile is-padding-3 has-text-weight-semibold has-text-centered has-text-redblack ${styles.contentsBandTitle}`}
                >
                  社会貢献活動
                </h2>
                <div className={styles.socialContributionsImageContainer}>
                  <picture>
                    <source srcSet={socialContributionsHeadingAvif} type="image/avif" />
                    <img
                      src={socialContributionsHeading}
                      alt="社会貢献活動"
                      loading="lazy"
                      width="1038"
                      height="582"
                    />
                  </picture>
                </div>
                <p className={styles.socialContributionsText}>
                  定額カルモくんを運営するナイル株式会社はモビリティ事業を行うものの社会的責任として、全国の交通安全の促進や、地方創生にも力を入れています。
                </p>
                {/* もっと見る */}
                <div className="has-text-centered">
                  <a
                    className="button radius-most is-large is-fixed-width-button is-margin-top-7"
                    href="/docs/social-contributions/"
                    // target="_blank"
                    rel="noreferrer"
                  >
                    もっと見る
                    <span
                      className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* // 新セクション_社会貢献活動  */}
        </div>
        {/* // pcでは2カラムになるためコンテナ追加 */}

        {/* お知らせ */}
        <section>
          <div
            className={`is-padding-4 has-background-white ${styles.contentsMarginTop}`}
          >
            <div className={styles.contentsSmallContainer}>
              <h2
                className={`is-size-2-tablet is-size-3-mobile is-padding-3 has-text-weight-semibold has-text-centered has-text-redblack ${styles.contentsBandTitle}`}
              >
                お知らせ
              </h2>
              <div
                className={`columns is-multiline ${styles.contentsMarginTop}`}
              >
                {/* キャンペーン 2024.10.1~ */}
                <div className="column is-full is-border-bottom-line is-padding-3">
                  <a href="/pr/2024/thanksgiving/" className="top-news-contents is-flex is-link-redbrown is-size-5" target="_blank" rel="noopener noreferrer">
                    <span className="icon is-small icon-arrow has-background-black" />
                    <span className="is-margin-left-7">
                      最大5万円が当たる！秋の大感謝祭2024
                      <span className="is-size-6 has-text-grey">
                        &nbsp;24.10.01
                      </span>
                    </span>
                  </a>
                </div>
                {/* キャンペーン 2024.7.1~ */}
                <div className="column is-full is-border-bottom-line is-padding-3">
                  <a href="/pr/2024/summer/" className="top-news-contents is-flex is-link-redbrown is-size-5" target="_blank" rel="noopener noreferrer">
                    <span className="icon is-small icon-arrow has-background-black" />
                    <span className="is-margin-left-7">
                      【終了】最大5万円が当たる！サマーキャンペーン！
                      <span className="is-size-6 has-text-grey">
                        &nbsp;24.07.01
                      </span>
                    </span>
                  </a>
                </div>
                {/* GW期間中の営業日について */}
                <div className="column is-full is-border-bottom-line is-padding-3">
                  <a href="/docs/info/20240426/" className="top-news-contents is-flex is-link-redbrown is-size-5" target="_blank" rel="noopener noreferrer">
                    <span className="icon is-small icon-arrow has-background-black" />
                    <span className="is-margin-left-7">
                      ゴールデンウィーク期間中の営業日について
                      <span className="is-size-6 has-text-grey">
                        &nbsp;24.04.26
                      </span>
                    </span>
                  </a>
                </div>

                {/* 令和6年能登半島地震に関するお知らせ */}
                <div className="column is-full is-border-bottom-line is-padding-3">
                  <a href="/docs/info/20240105/" className="top-news-contents is-flex is-link-redbrown is-size-5" target="_blank" rel="noopener noreferrer">
                    <span className="icon is-small icon-arrow has-background-black" />
                    <span className="is-margin-left-7">
                      令和6年能登半島地震に関するお知らせ
                      <span className="is-size-6 has-text-grey">
                        &nbsp;2024.01.05
                      </span>
                    </span>
                  </a>
                </div>
                {/* キャンペーン 2024.1.1~ */}
                <div className="column is-full is-border-bottom-line is-padding-3">
                  <a href="/pr/2024/newyear/" className="top-news-contents is-flex is-link-redbrown is-size-5" target="_blank" rel="noopener noreferrer">
                    <span className="icon is-small icon-arrow has-background-black" />
                    <span className="is-margin-left-7">
                      【終了】ハズレなし！最大3万円が当たる！お年玉キャンペーン
                      <span className="is-size-6 has-text-grey">
                        &nbsp;24.01.01
                      </span>
                    </span>
                  </a>
                </div>
                {/* 新規上場のお知らせ */}
                <div className="column is-full is-border-bottom-line is-padding-3">
                  <a href="/docs/info/20231220/" className="top-news-contents is-flex is-link-redbrown is-size-5" target="_blank" rel="noopener noreferrer">
                    <span className="icon is-small icon-arrow has-background-black" />
                    <span className="is-margin-left-7">
                      東京証券取引所グロース市場への新規上場に関するお知らせ
                      <span className="is-size-6 has-text-grey">
                        &nbsp;23.12.20
                      </span>
                    </span>
                  </a>
                </div>
                {/* カルモスリーのお知らせ */}
                <div className="column is-full is-border-bottom-line is-padding-3">
                  <a href="https://carmo-three.jp/" className="top-news-contents is-flex is-link-redbrown is-size-5" target="_blank" rel="noopener noreferrer">
                    <span className="icon is-small icon-arrow has-background-black" />
                    <span className="is-margin-left-7">
                      3年契約の自動車サブスクリプションサービス「カルモスリー」の提供を11月20日より開始
                      <span className="is-size-6 has-text-grey">
                        &nbsp;23.11.20
                      </span>
                    </span>
                  </a>
                </div>
                {/* ガイドライン改定のお知らせ */}
                <div className="column is-full is-border-bottom-line is-padding-3">
                  <a
                    href="/docs/info/20231010/"
                    className="top-news-contents is-flex is-link-redbrown is-size-5"
                  >
                    <span className="icon is-small icon-arrow has-background-black" />
                    <span className="is-margin-left-7">
                      ガイドライン改定のお知らせ
                      <span className="is-size-6 has-text-grey">
                        &nbsp;23.10.10
                      </span>
                    </span>
                  </a>
                </div>
                {/* かんたんメンテナンスプランの販売を開始しました */}
                <div className="column is-full is-border-bottom-line is-padding-3">
                  <a
                    href="/docs/maintenance/maintenance_kantan/"
                    className="top-news-contents is-flex is-link-redbrown is-size-5"
                  >
                    <span className="icon is-small icon-arrow has-background-black" />
                    <span className="is-margin-left-7">
                      かんたんメンテナンスプランの販売を開始しました
                      <span className="is-size-6 has-text-grey">
                        &nbsp;23.10.01
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              {/* もっと読む */}
              <div className="has-text-centered">
                <a
                  className="button radius-most is-large is-fixed-width-button is-margin-top-7 is-margin-bottom-7"
                  href="/information/"
                >
                  もっと見る
                  <span
                    className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* pcでは3カラムになるためコンテナ追加 */}
        <div className={`${styles.pageBottomFlexContainer} ${styles.contentsSmallContainer}`}>
          {/* カーリースお役立ちコラム */}
          <section id="columns">
            <div className={`${styles.commonSectionInner} ${styles.isUsefullColumn} ${styles.contentsSmallContainer}`}>
              <h2
                className={`is-size-3-mobile is-padding-3 has-text-weight-semibold has-text-centered has-text-redblack ${styles.contentsBandTitle}`}
              >
                カーリースお役立ちコラム
              </h2>
              <div
                className=""
              >
                <ColumnCarousel />
              </div>
              {/* もっと読む */}
              <div className="has-text-centered">
                <a
                  className="button radius-most is-large is-fixed-width-button is-margin-top-7"
                  href="/column/list/"
                  // target="_blank"
                  rel="noreferrer"
                >
                  もっと見る
                  <span
                    className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                  />
                </a>
              </div>
            </div>
          </section>

          {/*  バナーエリア → 関連情報として内容更新  */}
          <section>
            <div
              className={`${styles.commonSectionInner} ${styles.isBanners} ${styles.contentsSmallContainer}`}
            >
              <h2
                className={`is-size-3-mobile is-padding-3 has-text-weight-semibold has-text-centered has-text-redblack ${styles.contentsBandTitle}`}
              >
                関連情報
              </h2>
              <div className="is-padding-top-5 is-centered">
                {/* 中古車 */}
                <section>
                  <div className={`has-text-centered ${styles.jcCenter}`}>

                    <div className={`is-margin-bottom-3 ${styles.commonBannerItem}`} id="ab-usedcarBanner-original-sp">
                      <a href="/usedcar/" target="_blank" className="banner_area04">
                        <picture>
                          <source srcSet={bnrUsedcarAvif} type="image/avif" />
                          <img
                            className="w-100"
                            src={bnrUsedcar}
                            alt="納車をお急ぎの方は定額カルモくん 中古車がオススメ！定額カルモくん 中古車サイトへ"
                            loading="lazy"
                            width="345"
                            height="100"
                          />
                        </picture>
                      </a>
                    </div>

                    <div className={`is-margin-bottom-3 ${styles.commonBannerItem}`}>
                      <a
                        href="https://car-mo.jp/"
                        target="_blank"
                        className="banner_area04"
                        rel="noreferrer"
                      >
                        <img
                          className="w-100"
                          src={bnrMagazine}
                          alt="カルモマガジン By 定額カルモくん クルマ選びとカーライフを応援するWebメディア"
                          loading="lazy"
                          width="345"
                          height="100"
                        />
                      </a>
                    </div>

                    <div className={`column ${styles.commonBannerItem}`}>
                      <a
                        href="/contact?type=disposal"
                        target="_blank"
                        className="banner_area04"
                      >
                        <img
                          className="w-100"
                          src={sell}
                          alt="今のクルマを賢く売りませんか？"
                          loading="lazy"
                          width="345"
                          height="100"
                        />
                      </a>
                    </div>
                  </div>
                </section>

                {/* 取扱店一覧と法人・個人事業主のお客様はこちら */}
                <div className={`${styles.businessInformationContainer} is-variable is-1 is-mobile`}>
                  <Link
                    className={`column bnr_area_corp ${styles.commonBannerItem}`}
                    to={NEWCAR_DEFINE.PATH.SHOPLIST}
                  >
                    <picture>
                      <source srcSet={bnrShoplistAvif} type="image/avif" />
                      <img
                        src={bnrShoplist}
                        className="is-padding-bottom-2 w-100"
                        alt="定額カルモくん取扱加盟店はこちら"
                        loading="lazy"
                        width="340"
                        height="124"
                      />
                    </picture>
                  </Link>
                  <a
                    className={`column bnr_area_corp ${styles.commonBannerItem}`}
                    href={NEWCAR_DEFINE.PATH.CORPORATE_FORM}
                  >
                    <picture>
                      <source srcSet={corporateIndividualAvif} type="image/avif" />
                      <img
                        src={corporateIndividual}
                        alt="法人・個人事業主のお客様はこちら"
                        className={`is-padding-bottom-2 ${styles.commonBannerImage}`}
                        loading="lazy"
                        width="340"
                        height="124"
                      />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <div className={styles.nestContainer}>
            {/* カルモくん公式ソーシャルメディア */}
            <section>
              <div className="has-background-white">
                <div className={`${styles.commonSectionInner} ${styles.contentsSmallContainer}`}>
                  <h2
                    className={`is-size-3-mobile is-padding-3 has-text-weight-semibold has-text-centered has-text-redblack ${styles.contentsBandTitle}`}
                  >
                    公式ソーシャルメディアアカウント
                  </h2>
                  <div className={`columns is-centered is-mobile ${styles.snsIconContainer}`}>
                    <div
                      className="column is-narrow"
                    >
                      <a
                        href="https://www.youtube.com/channel/UCQIXe8-YDmE7Z0WzQ7Con5A"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <picture>
                          <source srcSet={ytLogoAvif} type="image/avif" />
                          <img
                            src={ytLogo}
                            className="is-margin-right-7"
                            alt="カルモくん公式YouTubeチャンネル"
                            width="134"
                            height="30"
                            loading="lazy"
                          />
                        </picture>
                      </a>
                    </div>
                    <div
                      className="column is-narrow"
                    >
                      <a
                        href="https://www.facebook.com/carmo0126/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <picture>
                          <source srcSet={facebookAvif} type="image/avif" />
                          <img
                            src={facebook}
                            className="is-margin-right-7"
                            alt="カルモくん公式facebook"
                            width="40"
                            height="40"
                            loading="lazy"
                          />
                        </picture>
                      </a>
                    </div>
                    <div
                      className="column is-narrow"
                    >
                      <a
                        href="https://twitter.com/carmomycar"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <picture>
                          <source srcSet={twitterAvif} type="image/avif" />
                          <img
                            src={twitter}
                            className="is-margin-right-7"
                            alt="カルモくん公式X"
                            width="40"
                            height="40"
                            loading="lazy"
                          />
                        </picture>
                      </a>
                    </div>
                    <div
                      className="column is-narrow"
                    >
                      <a
                        href="https://liff.line.me/1657071928-Br8GdoPL?sl=952e1e635d"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={line}
                          width="40"
                          height="40"
                          alt="カルモくん公式LINE"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* オリックス */}
            <section>
              <div
                className="has-background-white"
              >
                <div className={`${styles.commonSectionInner} ${styles.contentsSmallContainer}`}>
                  <h2
                    className={`is-size-3-mobile is-padding-3 has-text-weight-semibold has-text-centered has-text-redblack ${styles.contentsBandTitle}`}
                  >
                    提携先
                  </h2>
                  <div
                    className={`${styles.nestContentBottomContainer}`}
                  >
                    <div className="is-narrow is-hidden-touch is-padding-right-7 has-text-centered">
                      <picture>
                        <source srcSet={orixSignatureLogoAvif} type="image/avif" />
                        <img
                          src={orixSignatureLogo}
                          alt="おトクにマイカー 定額カルモくんは、オリックス自動車株式会社との提携サービスとなり、お客様にお届けする新車は、すべてオリックス自動車が扱う正規ディーラー車です。"
                          width="250"
                          height="62"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div className="column is-narrow is-hidden-desktop is-margin-bottom-7 has-text-centered">
                      <picture>
                        <source srcSet={orixSignatureLogoAvif} type="image/avif" />
                        <img
                          src={orixSignatureLogo}
                          alt="おトクにマイカー 定額カルモくんは、オリックス自動車株式会社との提携サービスとなり、お客様にお届けする新車は、すべてオリックス自動車が扱う正規ディーラー車です。"
                          width="250"
                          height="62"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div
                      className={`has-text-left has-text-black-ter is-size-4-desktop is-size-5-touch has-text-weight-semibold ${styles.top_orix_line}`}
                    >
                      おトクにマイカー
                      定額カルモくんは、オリックス自動車株式会社との提携サービスとなり、お客様にお届けする新車は、すべてオリックス自動車が扱う正規ディーラー車です。
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* // pcでは3カラムになるためコンテナ追加 */}
        <p className="has-text-centered is-size-7 is-padding-4">※この記事は2024年9月4日時点の情報で制作しています</p>
      </main>

      <Footer />
      <ScrollToTop />
    </Layout>
  );
};

export default Page;
