import React from 'react';
import * as styles from '@/newcar/ui/top/template/index.module.styl';

interface DifferencePoints {
  id: number;
  title: string | JSX.Element;
  htmlContent: string | JSX.Element;
  addOrderClass: string;
  notice?: string;
}

export const DifferencePointList: ReadonlyArray<Readonly<DifferencePoints>> = [
  {
    id: 1,
    title: (
      <>
        業界最安水準
        <span className="is-size-6">※</span>
        、
        <br className={styles.isShowDesktop} />
        月1万円台〜
      </>
    ),
    htmlContent: (
      <>
        <ul className={styles.differencePointsList}>
          <li>頭金・ボーナス払い不要！</li>
          <li>税金や自賠責保険料などコミコミ！</li>
          <li>メンテ加入で車検も月額コミ！</li>
        </ul>
        <p>
          <span className="has-text-weight-bold">業界最安水準※の価格</span>
          で新車を月額1万円台から持てます。
          <span className="has-text-weight-bold">頭金などの初期費用や、ボーナス払いは不要。</span>
          <span className="is-first-notice">
            ※文末の制作日における調査結果に基づく。
            <br />
            調査概要はコンテンツポリシー参照。
          </span>
        </p>
        <p>
          月額料金には
          <span className="has-text-weight-bold">自動車税や重量税、自賠責保険料といった法定費用がコミコミ</span>
          なのもカーリースの特徴です。
          さらに、定額カルモくんは
          <span className="has-text-weight-bold">メンテナンスプランにご加入いただくと車検代も月額化</span>
          でき、
          方々の支払いが一本化できるので、家計の管理がラクになります。
        </p>
        <div className="difference-button-first-container has-text-centered">
          <a
            className={`button radius-most is-large is-fixed-width-button ${styles.differenceAccordionButtonFirst}`}
            href="/column/newcar/carmo-explanation/"
          >
            コスパが良い理由は？
            <span
              className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
            />
          </a>
          <a
            className={`button radius-most is-large is-fixed-width-button ${styles.differenceAccordionButtonSecond}`}
            href="/column/newcar/lease-or-buy/"
          >
            車は購入かカーリースか？
            <span
              className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
            />
          </a>
        </div>
      </>
    ),
    addOrderClass: 'is-first',
  },
  {
    id: 2,
    title: (
      <>
        追加料金なしで
        <br className={styles.isShowDesktop} />
        走り放題！
        <span className="is-size-7 has-text-weight-normal">※1</span>
      </>
    ),
    htmlContent: (
      <>
        <ul className={styles.differencePointsList}>
          <li>
            走行距離の制限なし！
            <span className="is-size-7 has-text-weight-normal">※1</span>
          </li>
          <li>
            契約満了時に車がもらえる！
            <span className="is-size-7 has-text-weight-normal">※2</span>
          </li>
          <li>オプションも組み合わせ自由！</li>
        </ul>
        <p>
          <span className="has-text-weight-bold">7年以上のご契約なら走行距離の制限はありません</span>
          ので、
          お車を日常的に使われるお客様も安心してご利用いただけます。
          また、
          <span className="has-text-weight-bold">もらえるオプションにご加入いただくと契約満了時に返却しなくてよい</span>
          ので
          車検に通る範囲のカスタマイズもペットと一緒にドライブもOK！
        </p>
        <p className="is-first-notice">
          ※1）新車は7年以上のご契約の場合
          <br />
          ※2）新車は7年以上のご契約でもらえるオプション加入時
        </p>
        <p>
          お車はすべて
          <span className="has-text-weight-bold">正規ディーラーから納車される新車</span>
          です。
          <span className="has-text-weight-bold">
            カラーやグレード、メーカーオプションもディーラーオプションも
            ご予算に合わせて自由に組み合わせいてただけます
          </span>
          のでお客様だけの特別なマイカーとしてカーライフをお楽しみいただけます。
        </p>
        <div className="has-text-centered">
          <a
            className={`button radius-most is-large is-fixed-width-button is-margin-top-7 ${styles.differencePointsListButton} ${styles.isSecond}`}
            href="/column/newcar/carlease-recommend/"
          >
            マイカーリースの選び方とは？
            <span
              className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
            />
          </a>
        </div>
      </>
    ),
    addOrderClass: 'is-second',
  },
  {
    id: 3,
    title: (
      <>
        納車まで
        <br className={styles.isShowDesktop} />
        自宅で完結！
      </>
    ),
    htmlContent: (
      <>
        <ul className={styles.differencePointsList}>
          <li>24時間ネットで申し込み可能！</li>
          <li>店舗に行かなくてOK、自宅で完結</li>
          <li>ディーラーから自宅に納車</li>
        </ul>
        <p>
          <span className="has-text-weight-bold">24時間ネットでおクルマ選びからお申し込みまで完了</span>
          いただけます。
          忙しくて店舗に行けない方や、店舗が家から遠い方なども自分のペースで進められるのでタイパも抜群！
        </p>
        <p>
          また、
          <span className="has-text-weight-bold">契約手続きも郵送で完結。</span>
          担当ディーラーから
          <span className="has-text-weight-bold">ご自宅まで納車</span>
          いたしますので、自宅にいながらカンタンにカーライフが始められます！
        </p>
      </>
    ),
    addOrderClass: 'is-third',
  },
  {
    id: 4,
    title: (
      <>
        メンテ整備工場
        <br className={styles.isShowDesktop} />
        は全国多数！
      </>
    ),
    htmlContent: (
      <>
        <ul className={styles.differencePointsList}>
          <li>全国にメンテ指定工場が多数</li>
          <li>選べる3つのメンテナンスプラン</li>
          <li>メンテ加入で車検代も月額コミ</li>
        </ul>
        <p className="is-margin-bottom-4">
          <span className="has-text-weight-bold">メンテナンス・車検を受けていただける指定整備工場は全国に多数</span>
          にあり、ご自宅のお近くなど好きなところをご利用いただけます。もしお客様のご利用したい工場が指定工場でなかった場合は、ご相談いただくことも可能です。
        </p>
        <p className="is-margin-bottom-4">
          またメンテナンスは、
          <span className="has-text-weight-bold">保証内容の手厚さが選べる3つのメンテナンスプラン</span>
          をご用意。
          <br />
          どのプランが良いかわからない…という方もご安心ください。お客様のお車のご利用イメージをヒアリングし、詳細のご説明と最適なプランのご案内をいたします。
        </p>
        <ul className="is-margin-bottom-4">
          <li className="has-text-weight-bold">
            <span className={styles.differencePointsListPlatinum}>● </span>
            プラチナ：長く乗りたい方におすすめ
          </li>
          <li className="has-text-weight-bold">
            <span className={styles.differencePointsListGold}>● </span>
            ゴールド：一番スタンダードなプラン
          </li>
          <li className="has-text-weight-bold">
            <span className={styles.differencePointsListSilver}>● </span>
            シルバー：必要最低限でOKな方に
          </li>
        </ul>
        <table className={styles.differencePointsTable}>
          <tr>
            <th className={styles.differencePointsTableThSub}>対象項目</th>
            <th className={styles.differencePointsTableThPlatinum}>プラチナ</th>
            <th className={styles.differencePointsTableThGold}>ゴールド</th>
            <th className={styles.differencePointsTableThSilver}>シルバー</th>
          </tr>
          <tr>
            <td className={styles.differencePointsTableTdSub}>原状回復費用、車検点検料、エンジンオイル交換など</td>
            <td className={styles.differencePointsTableTdPlatinum}>◯</td>
            <td className={styles.differencePointsTableTdGold}>◯</td>
            <td className={styles.differencePointsTableTdSilver}>◯</td>
          </tr>
          <tr>
            <td className={styles.differencePointsTableTdSub}>タイヤメンテナンス、ブレーキオイル交換、ブレーキパッド交換など</td>
            <td className={styles.differencePointsTableTdPlatinum}>◯</td>
            <td className={styles.differencePointsTableTdGold}>◯</td>
            <td className={styles.differencePointsTableTdSilver}>×</td>
          </tr>
          <tr>
            <td className={styles.differencePointsTableTdSub}>メーカー一般保証終了後の長期延長保証</td>
            <td className={styles.differencePointsTableTdPlatinum}>
              ◯
              <span className="is-size-7 has-text-weight-normal">※1</span>
            </td>
            <td className={styles.differencePointsTableTdGold}>
              ◯
              <span className="is-size-7 has-text-weight-normal">※2</span>
            </td>
            <td className={styles.differencePointsTableTdSilver}>
              ◯
              <span className="is-size-7 has-text-weight-normal">※2</span>
            </td>
          </tr>
          <tr>
            <td className={styles.differencePointsTableTdSub}>メーカー特別保証終了後の長期延長保証</td>
            <td className={styles.differencePointsTableTdPlatinum}>
              ◯
              <span className="is-size-7 has-text-weight-normal">※1</span>
            </td>
            <td className={styles.differencePointsTableTdGold}>×</td>
            <td className={styles.differencePointsTableTdSilver}>×</td>
          </tr>
        </table>
        <ul className="is-margin-top-4">
          <li className="is-size-7">※プラチナプランはリース期間6年〜11年でご加入いただけます。</li>
          <li className="is-size-7">※1 リース期間終了または走行距離10万km</li>
          <li className="is-size-7">※2 メーカー保証期間後から2年または走行距離10万km</li>
        </ul>
        <div className="has-text-centered">
          <a
            className={`button radius-most is-large is-fixed-width-button is-margin-top-7 ${styles.differencePointsListButton}`}
            href="/docs/maintenance/"
          >
            メンテナンスプランの詳細をみる
            <span
              className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
            />
          </a>
        </div>
      </>
    ),
    addOrderClass: 'is-fourth',
  },
  {
    id: 5,
    title: (
      <>
        未使用車や
        <br className={styles.isShowDesktop} />
        中古車も選べる！
      </>
    ),
    htmlContent: (
      <>
        <ul className={styles.differencePointsList}>
          <li>プロの査定士が厳選、鑑定書付き</li>
          <li>
            車検2年・故障補償1年付き
            <span className="is-size-7 has-text-weight-normal">※1</span>
          </li>
          <li>
            納車が最短３週間〜
            <span className="is-size-7 has-text-weight-normal">※2</span>
          </li>
        </ul>
        <p>
          定額カルモくんの中古車リースは常時、
          <span className="has-text-weight-bold">全国数千台</span>
          をご用意。
          <span className="has-text-weight-bold">プロの査定士が厳選して仕入れ、第三者機関による鑑定書付</span>
          の中古車も取り揃えておりますので、お客様のご条件にあった１台がきっと見つかります。
        </p>
        <p>
          また、サイト掲載車両は
          <span className="has-text-weight-bold">全て車検が2年、修理費上限なしの故障保証1年付き</span>
          ※1なので安心してお乗りいただけます。中古車は新車に比べ
          <span className="has-text-weight-bold">納車期間も短い</span>
          のでお急ぎの方も、そうでない方もお気軽にご相談ください。
        </p>
        <p className="is-first-notice">
          ※1）一部、保証がつかない車両もございます
          <br />
          ※2）中古車の場合。車種・地域による
        </p>
        <div className="has-text-centered">
          <a
            className="button radius-most is-large is-fixed-width-button is-margin-top-7"
            href="/usedcar/lineup/"
          >
            中古車一覧をみる
            <span
              className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
            />
          </a>
        </div>
      </>
    ),
    addOrderClass: 'is-fifth',
  },
];
